import React, { useEffect, useState } from 'react'
import logo from "../images/TCARE Logo - Primary Horizontal - Color REV.png"
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Spin } from 'antd';
// import azureLogo from '../images/azure-active-directory-seeklogo.com.svg'

var redirectLogin = null

const Login = () => {
    const [loading, setLoading] = useState(false)

    const { instance } = useMsal();
    const initAzureLogin = () => {
        if(loading) return
        setLoading(true)
        instance.loginRedirect(loginRequest)
        .then((res) => {
        })
        .catch(e => {
            console.log(e);
        })
    }

    useEffect(() => {
        redirectLogin = setTimeout(() => {
            initAzureLogin()
        }, 500)

        return () => {
            clearTimeout(redirectLogin)
        }
    }, []) // eslint-disable-line

    return (
        <div className="min-h-screen flex justify-center items-center flex-col bg-gray-100 dark:bg-ddark overflow-hidden">
            <img alt="logo" src={ logo } className="fixed left-8 top-5 w-48" />
            {/* <div className="bg-gray-200 dark:bg-ldark !bg-opacity-90 shadow-lg flex flex-col overflow-hidden items-center p-6 rounded-2xl relative w-[400px] sm:w-[600px]"> */}
                <Spin size="large" />
                {/* <div className="text-3xl text-tcareRuby font-medium mb-3 flex justify-between content-center w-full px-4">
                    Login
                </div>
                <button type="button" className={`flex justify-between items-center w-80 mt-8 mb-4 relative bg-gradient-to-tr from-tcareRuby to-[#FE8EA5] text-lg text-white px-4 py-2 rounded-md hover:ring-2 hover:ring-offset-2 hover:ring-offset-gray-200 dark:hover:ring-offset-ldark hover:ring-tcareRuby hover:shadow-xl transition-all duration-300 align-middle outline-none focus:outline-none font-bold ${ loading ? 'opacity-50 animate-pulse' : ''}`} onClick={ initAzureLogin }>
                    <img width="40px" src={ azureLogo } alt="Azure AD" />
                    <div>
                        Continue with Azure-AD
                    </div>
                    <div></div>
                </button> */}
            {/* </div> */}
        </div>
    )
}

export default Login
