import schema from "./schema.json"

const get_type = (inp) => {
    let type = ''
    let is_list = false
    let is_null = true
    let out = {type, list: is_list, nullable: is_null}
    if(inp.kind === 'LIST') is_list = true
    if(inp.kind === 'NON_NULL') is_null = false
    if(inp.name !== null && inp.name !== undefined) {
        out.type = inp.name
        out.list = is_list
        out.nullable = is_null
    } else if(inp.ofType !== null) {
        let y = get_type(inp.ofType)
        out.type = y.type
        out.list = !is_list ? y.list : is_list
        out.nullable = is_null ? y.nullable : is_null
    }
    return out
}

export const getSchemaObject = (title) => {
    let objects = {}
    schema['__schema'].types.forEach(c => {
        if(c.kind !== 'OBJECT' || c.name === 'Query' || c.name === 'Mutation') return
        if(objects[c.name] === undefined) objects[c.name] = {name: c.name, fields: {}}
        c.fields.forEach(e => {
            let { type, list } = get_type(e.type)
            objects[c.name].fields[e.name] = {name: e.name, type, list}
        })
    })
    if(title === 'all') return objects
    return objects[title]
}

export const getQueryObject = (query) => {
    let objects = {args: [], value: {}}
    if(query === null || query === undefined || query === '') return objects
    let q = schema['__schema'].types.find(c => c.name === 'Query').fields.find(c => c.name === query)
    objects.args = q.args.map(c => {
        return buildMutaion(c)
    })
    let {type, list} = get_type(q.type)
    objects.value = {type, list}
    return objects
}

export const buildObject = (data, type) => {
    let ob = getSchemaObject(type)
    if (ob !== undefined) {
        data.push(ob)
        Object.keys(ob.fields).forEach(e => {
            let c = ob.fields[e]
            if (c.type !== 'Int' && c.type !== 'String' && c.type !== 'Boolean') {
                if (data.find(r => r.name === c.type) === undefined) {
                    let r = buildObject(data, c.type)
                    data = r
                }
            }
        })
    }
    return data
}

export const buildMutaion = (c) => {
    let a = {name: c.name, type: '', list: false, nullable: true, choices: [], children: []}
    let type = get_type(c.type)
    if(type.list) a.list = true
    if(type.nullable === false) a.nullable = false
    if(type.type === 'Int' || type.type === 'String' || type.type === 'Boolean') a.type = type.type
    else {
        let q = schema['__schema'].types.find(c => c.name === type.type)
        if(q.kind === 'ENUM') {
            a.type = 'String'
            a.choices = q.enumValues.map(c => ({text: c.name, value: c.name}))
        } else if(q.kind === 'INPUT_OBJECT') {
            // a.name = q.name
            a.children = q.inputFields.map(e => buildMutaion(e))
        }
    }
    return a
}

export const getMutationObject = (query) => {
    let objects = {args: [], value: {}}
    if(query === null || query === undefined || query === '') return objects
    let q = schema['__schema'].types.find(c => c.name === 'Mutation').fields.find(c => c.name === query)
    objects.args = q.args.map(c => {
        let y = buildMutaion(c)
        return y
    })
    let {type, list} = get_type(q.type)
    objects.value = {type, list}
    return objects
}

export const createInputObject = obj => {
    let o = {}
    obj.forEach(c => {
        if (c.list) {
            if(c.name === 'applications' && c.choices.length > 0) o[c.name] = c.choices.map(e => e.value)
            else o[c.name] = []
        } else if (c.children.length === 0) {
            if(c.type === 'Boolean') o[c.name] = c.name === 'active' ? 'null' : false
            else if(c.name === 'status' && c.choices.length > 0 && c.choices.find(e => e.value === 'ACTIVE')) o[c.name] = 'ACTIVE'
            else o[c.name] = null
        } else o[c.name] = createInputObject(c.children)
    })
    return o
}

export const getFormValue = (name, value) => {
    if(value === null) return ''
    let nameSplit = name.split('.')
    if(nameSplit.length > 1) {
        value = getFormValue(nameSplit.slice(1,).join('.'), value[nameSplit[0]])
    } else value = value[nameSplit[0]]
    return value
}

export const buildQueryParams = (name, j, fieldsData, displayDataTree, queryObject) => {
    let q = queryObject.find(c => c.name === name)
    let field = fieldsData[name]
    let ret = ''
    if(q) {
        Object.keys(q.fields).forEach(r => {
            let c = q.fields[r]
            if(field[c.name]) {
                if(fieldsData[c.type] !== undefined) {
                    if(displayDataTree[j + 1] !== undefined && c.name === displayDataTree[j + 1].query.name) {
                        let param = ''
                        if(displayDataTree[j + 1].value.pageSize !== undefined) {
                            param = `(pageable: {pageNumber:${displayDataTree[j + 1].value.value}, pageSize:${displayDataTree[j + 1].value.pageSize}})`
                        }
                        ret += `${c.name}${param} {\n`
                        ret += buildQueryParams(c.type, j + 1, fieldsData, displayDataTree, queryObject)

                        // add organization to query for assistusers default
                        if(c.name === "assistUsers" && ret.indexOf("organizationName") === -1) {
                            ret += `organization {\norganizationName\n}\n`
                        }

                        ret += `\n}\n`
                    }
                } else if(c.name !== 'join') ret += `${c.name}\n`
            }
        })
    }
    return ret
}