import { Select } from "antd"
import { useEffect, useMemo, useState } from "react"
import SideBar from "../components/SideBar"
import TopBar from "../components/TopBar"
import QueryFlow from "../components/QueryFlow"
import DataFlow from "../components/DataFlow"
import { PageMutations, PageQueries } from "../data/queries"
import AddMenu from "../components/AddMenu"
import NewItem from "../components/NewItem"
import { useDispatch } from "react-redux"
import { setDisplayDataTree, setLastQuery, setSearchArgs } from "../store/common/reducers"
import BulkAdd from "../components/BulkAdd"

const { Option } = Select;

const typeOptions = [
    {text: "Azure", value: "azure"}
]

const queries = PageQueries

const addQueries = PageMutations.azure

const Azure = () => {

    const [active, setActive] = useState('data')
    const [query, setQuery] = useState(null)
    const [optionType, setOptionType] = useState(typeOptions[0]?.value)
    const [showNewItem, setShowNewItem] = useState(false)
    const [create, setCreate] = useState(null)
    const [showBulkAdd, setShowBulkAdd] = useState(false)

    const dispatch = useDispatch()

    const queryOptions = useMemo(() => {
        setQuery(null)
        if(queries[optionType] === undefined) return []
        return queries[optionType].queries.map(c => ({text: c, value: c}))
    }, [optionType])

    useEffect(() => {
        dispatch(setSearchArgs([]))
        dispatch(setDisplayDataTree([]))
        dispatch(setLastQuery(''))
    }, [dispatch])

    const addNew = val => {
        setCreate(val)
        setShowNewItem(true)
    }

    const addBulk = val => {
        setCreate(val)
        setShowBulkAdd(true)
    }

    const closeAddItem = () => {
        setCreate(null)
        setShowNewItem(false) 
    }

    const closeBulkAdd = () => {
        setCreate(null)
        setShowBulkAdd(false)
    }

    return (
        <div className="flex flex-col h-screen">
            <TopBar />
            <div className="flex flex-1 gap-5 overflow-y-auto">
                <SideBar />
                <div className="flex flex-col pt-4 w-full">
                    <div className="flex gap-4 pl-12 w-full query-filter">
                        <div>
                            <Select value={optionType} style={{ width: 150 }} onChange={val => setOptionType(val)} className="border border-bluegray-500 text-white rounded-sm" dropdownClassName="text-white" bordered={false}>
                                {
                                    typeOptions.map(c => (
                                        <Option key={c.value} value={c.value}>{ c.text }</Option>
                                    ))
                                }
                            </Select>
                        </div>
                        <div className="w-0.5 bg-bluegray-500"></div>
                        <div>
                            <Select placeholder="Select Query" value={query} style={{ width: 200 }} onChange={val => setQuery(val)} className="border border-bluegray-500 text-white rounded-sm" dropdownClassName="text-white" bordered={false}>
                                {
                                    queryOptions.map(c => (
                                        <Option key={c.value} value={c.value}>{ c.text }</Option>
                                    ))
                                }
                            </Select>
                        </div>
                        <div className="w-0.5 bg-bluegray-500"></div>
                        <div className={`flex items-center justify-center`}>
                            <div className={`font-bold px-7 py-1 rounded-lg text-[14px] text-white cursor-pointer hover:-translate-y-0.5 hover:scale-105 transition-all duration-300 select-none ${active === 'query' ? 'bg-tcareRuby' : 'bg-bluegray-600'}`} onClick={() => setActive('query')}>
                                Query
                            </div>
                        </div>
                        <div className={`flex items-center justify-center`}>
                            <div className={`font-bold px-8 py-1 rounded-lg text-[14px] text-white cursor-pointer hover:-translate-y-0.5 hover:scale-105 transition-all duration-300 select-none ${active === 'data' ? 'bg-tcareRuby' : 'bg-bluegray-600'}`} onClick={() => setActive('data')}>
                                Data
                            </div>
                        </div>
                        {
                            (addQueries || []).length > 0 &&
                            <>
                                <div className="w-0.5 bg-bluegray-500"></div>
                                <AddMenu options={ addQueries || [] } onSelect={val => addNew(val)} />
                                <AddMenu options={ addQueries || [] } onSelect={val => addBulk(val)} title="Bulk Add" />
                            </>
                        }
                    </div>
                    <div className="flex flex-1 w-full my-4 pr-4 overflow-auto">
                        {
                            query !== null && (
                                active === 'query' ? 
                                    <QueryFlow query={query} />
                                :
                                    <DataFlow query={query} />
                            )
                        }
                    </div>
                </div>
            </div>
            {
                showNewItem &&
                <NewItem create={ create } onClose={ () => closeAddItem()} title={`${addQueries.find(c => c.value === create)?.text}`} />
            }
            {
                showBulkAdd &&
                <BulkAdd create={ create } onClose={() => closeBulkAdd()} title={`Bulk Add "${addQueries.find(c => c.value === create)?.text}"`} />
            }
        </div>
    )
}

export default Azure