import { useContext } from "react"
import logo from "../images/TCARE Logo - Primary Horizontal - Color REV.png"
import { AuthContext } from "./Auth"

const TopBar = () => {

    const { sessionData } = useContext(AuthContext)

    return (
        <div className="p-4">
            <div className="flex justify-between">
                <div className="flex justify-center items-center gap-4">
                    <img alt="logo" src={logo} width="150px" />
                    <span className="font-bold text-xl text-tcarePeriwinkle">({process.env.REACT_APP_ENV})</span>
                </div>
                <div className="mr-8 text-white text-[18px] font-bold">
                    { sessionData }
                </div>
            </div>
        </div>
    )
}

export default TopBar