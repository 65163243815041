import { Button, Dropdown, Menu } from "antd"
import { useMemo } from "react"
import { IoIosArrowDown } from "react-icons/io"

const AddMenu = ({ options, onSelect, title="Add New" }) => {

    const selectOption = val => {
        if (typeof onSelect === 'function') onSelect(val)
    }

    const menu = useMemo(() => {

        return <Menu className="min-w-[200px]" items={
            options.filter(c => !c.hidden).map(c => (
                {
                    key: c.value,
                    label: (
                        <div className="pr-5" onClick={() => selectOption(c.value)}>{c.text}</div>
                    )
                }
            ))
        }
        />
    }, [options]) // eslint-disable-line

    return (
        <div className="relative overflow-visible">
            <Dropdown overlay={menu}>
                <Button className="flex items-center gap-0.5 !bg-transparent !text-tcareRuby font-bold pl-3 rounded-lg border-0">{ title }<IoIosArrowDown className="text-lg mt-1" /></Button>
            </Dropdown>
        </div>
    )
}

export default AddMenu