export const PageQueries = {
    user: {
        queries: ['assistUsers', 'assistUserByEmail', 'assistUserById', 'assistUsersByOrgId', 'assistUsersByOrgName', 'tcareUserProfileByEmail']
    },
    organization: {
        queries: ['organizations', 'organizationById', 'organizationByName', 'childOrganizationsByParentOrgId', 'childOrganizationsByParentOrgName', 'parentOrganizationsByChildOrgId', 'parentOrganizationsByChildOrgName']
    },
    moodle: {
        queries: ['moodleCourseById', 'moodleCourseByIdNumber', 'moodleCourses', 'moodleUserByEmail', 'getCourseByShortName']
    },
    azure: {
        queries: ['azureUserByEmail', 'azureUserById', 'azureUserByPrincipalName']
    }
}

export const PageMutations = {
    user: [
        // {text: 'Organization', value: 'createOrganization'},
        {text: 'Get or Create Organization', value: 'getOrCreateOrganization'},
        {text: 'Get or Create Assist User', value: 'getOrCreateAssistUser'},
        {text: 'Get or Create Production Assist User', value: 'getOrCreateProdAssistUser'},
        {text: 'Get or Create Sandbox Assist User', value: 'getOrCreateSandboxAssistUser'},
        {text: 'Create TCARE User', value: 'createTcareUser'},
        {text: 'Disable TCARE User', value: 'disableTcareUser'},
        {text: 'Sync User', value: 'syncUser'},
        {text: 'Change TCARE User Email', value: 'changeTcareUserEmail', hidden: true},
        {text: 'Update TCARE User', value: 'updateTcareUser', hidden: true},
        {text: 'Activate TCARE User', value: 'activateTcareUser', hidden: true},
        {text: 'Disable TCARE User', value: 'disableTcareUser', hidden: true}
    ],
    moodle: [
        {text: 'Get or Create Moodle User', value: 'getOrCreateMoodleUser'}
    ],
    azure: [
        {text: 'Get or Create Azure B2C User', value: 'getOrCreateAzureB2CUser'}
    ]
}