import { Button } from "antd"
import { useEffect, useState } from "react"
import { BsThreeDotsVertical } from "react-icons/bs"

const FieldActions = ({ fieldActions, c, record, createNewFromArray }) => {

    const [open, setOpen] = useState(false)

    const toggleMenu = (ev) => {
        ev.stopPropagation()   
        setOpen(!open)
    }
    const closeMenu = () => {
        setOpen(false)
    }
    useEffect(() => {
        if(open) {
            setTimeout(() => {
                window.addEventListener('click', closeMenu)
            }, 500)
        } else {
            window.removeEventListener('click', closeMenu)
        }
        return () => window.removeEventListener('click', closeMenu)
    }, [open])
 
    return <div className="flex flex-col gap-2 relative transform rotate-0 z-10">
                                    
        <button onClick={toggleMenu}>
            <BsThreeDotsVertical size={20} />
        </button>
        { open && 
            <div className={`fixed top-full right-10 bg-tcareAqua rounded-lg p-1 flex flex-col items-start gap-1 z-[99999]`}>
                {
                    fieldActions[c.query.type].map(act => {
                        if(act.condition === undefined) {
                            return <Button key={act.name} size="small" className={`!bg-tcareAqua text-black border-0`} onClick={() => createNewFromArray(act, record)}>{act.name}</Button>
                        } else {
                            if(act.condition.operator === '==') {
                                if(record[act.condition.left] === act.condition.right) {
                                    return <Button key={act.name} size="small" className={`!bg-tcareAqua text-black border-0`} onClick={() => createNewFromArray(act, record)}>{act.name}</Button>
                                }
                            } else if(act.condition.operator === '!=') {
                                if(record[act.condition.left] !== act.condition.right) {
                                    return <Button key={act.name} size="small" className={`!bg-tcareAqua text-black border-0`} onClick={() => createNewFromArray(act, record)}>{act.name}</Button>
                                }
                            }
                        }
                        return null
                    }).filter(act => act !== null)
                }
            </div>
        }
    </div>
}

export default FieldActions