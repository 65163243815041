import { useDispatch, useSelector } from "react-redux"
import { AutoComplete, Button, Checkbox, Input, InputNumber, Select } from 'antd'
import { createInputObject, getFormValue } from '../data/parseSchema'
import { setAddFormColumns, setAddFormValues } from '../store/common/reducers';
import { RiDeleteBin5Line } from 'react-icons/ri'
import { AiOutlineWarning } from 'react-icons/ai'

const { Option } = Select;

const BuildBlock = ({ blocks, name, isBulk=false, tableColumns=[], query= {} }) => {

    const { addFormValues, addFormColumns, orgNames, showFullForm } = useSelector(state => state.common)
    const dispatch = useDispatch()

    const loopChangeValues = (name, value, currentValue, action="update") => {
        let nameSplit = name.split('.')
        if(nameSplit.length > 1) {
            value = loopChangeValues(nameSplit.slice(1,).join('.'), value, currentValue[nameSplit[0]], action)
        }
        if(typeof currentValue === 'object' && Array.isArray(currentValue)) {
            if(action === 'update' || nameSplit.length > 1) {
                return currentValue.map((c, i) => {
                    if(i === parseInt(nameSplit[0])) {
                        return value
                    }
                    return c
                })
            } else if(action === 'insert') {
                return [...currentValue, value]
            } else if(action === 'remove') {
                return currentValue.filter((c, i) => i !== parseInt(nameSplit[0]))
            }
        } else if(typeof currentValue === 'object') {
            return {...currentValue, [nameSplit[0]]: value}
        }
    }

    const changeValue = (name, value) => {
        let v = loopChangeValues(name, value, addFormValues)
        dispatch(setAddFormValues(v))
    }

    const setTableColumn = (name, value) => {
        let v = loopChangeValues(name, value, addFormColumns)
        dispatch(setAddFormColumns(v))
    }

    const appendRow = (name, row) => {
        let c = createInputObject(row)
        let v = loopChangeValues(name+'.0', c, addFormValues, 'insert')
        dispatch(setAddFormValues(v))
        v = loopChangeValues(name+'.0', c, addFormColumns, 'insert')
        dispatch(setAddFormColumns(v))
    }

    const removeRow = (name) => {
        let v = loopChangeValues(name, '', addFormValues, 'remove')
        dispatch(setAddFormValues(v))
        v = loopChangeValues(name, '', addFormColumns, 'remove')
        dispatch(setAddFormColumns(v))
    }

    return <div className='flex flex-col gap-2'>
        {
            blocks.map((c, i) => {
                if(c.more === true && showFullForm === false) return null
                let n = `${name}${name !== '' ? '.' : ''}${c.name}`
                let input = null
                let inputVal = getFormValue(n, addFormValues)
                var disabled = query?.disabled?.includes(c.name)
                if (c.name === "organizationName" || c.name === "parentOrganizationName") {
                    input = <AutoComplete value={inputVal} size='middle' allowClear style={{ width: '100%' }} placeholder={`Please enter ${c.name}`} onChange={val => changeValue(n, val)} options={orgNames.filter(opt => opt?.toLowerCase().includes(inputVal?.toLowerCase())).map(opt => ({value: opt}))} disabled={disabled} />
                } else if (c.choices.length > 0 && c.list) {
                    input = <Select value={getFormValue(n, addFormValues)} size='middle' mode="multiple" allowClear style={{ width: '100%' }} placeholder={`Please select ${c.name}`} onChange={val => changeValue(n, val)} disabled={disabled} >
                        {
                            c.choices.map(e => (
                                <Option key={e.value} value={e.value}>{ e.text }</Option>
                            ))
                        }
                    </Select>
                } else if(c.choices.length > 0) {
                    input = <Select value={getFormValue(n, addFormValues)} size='middle' style={{ width: '100%' }} placeholder={`Please select ${c.name}`} onChange={val => changeValue(n, val)} disabled={disabled} >
                        {
                            c.choices.map(e => (
                                <Option key={e.value} value={e.value}>{ e.text }</Option>
                            ))
                        }
                    </Select>
                } else if(c.type === 'Int') {
                    input = <InputNumber value={getFormValue(n, addFormValues)} size='middle' placeholder={c.name} onChange={val => changeValue(n, val)} disabled={disabled} />
                } else if(c.type === 'String') {
                    input = <Input size='middle' width="200px" placeholder={c.name} value={getFormValue(n, addFormValues)} onChange={val => changeValue(n, val.target.value)} disabled={disabled} />
                } else if(c.type === 'Boolean') {
                    input = <Checkbox size='middle' value={getFormValue(n, addFormValues)} onChange={val => changeValue(n, val.target.checked)} disabled={disabled} />
                } else if(c.list && c.children.length > 0) {
                    return <div key={i} className={`border border-tcareDarkPurple rounded p-2`}>
                        <div className='flex items-center gap-3'>
                            <div className='font-bold capitalize'>{c.name}</div>
                            <Button size='small' className='!bg-tcareRuby !text-white font-bold px-3 border-0' onClick={() => appendRow(n, c.children)}>Add {c.name}</Button>
                        </div>
                        <div className="pb-4">
                            {
                                getFormValue(n, addFormValues).map((r, j) => {
                                    let y = `${n}.${j}`
                                    return <div key={j} className="relative m-2 border-2 border-tcareDarkPurple rounded p-2">
                                        <div className="absolute top-2 right-2 cursor-pointer" onClick={() => removeRow(y)}><RiDeleteBin5Line size="20px" className="text-red-700" /></div>
                                        <BuildBlock key={j} blocks={c.children} name={y} isBulk={isBulk} tableColumns={tableColumns} query={ query } />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                } else if (c.children.length > 0) {
                    return <div>
                        <div className='flex items-center gap-3'>
                            <div className='font-bold capitalize'>{c.name}</div>
                        </div>
                        <div className="my-2 pl-4 border border-tcareDarkPurple rounded p-2">
                            <BuildBlock key={i} blocks={c.children} name={n} isBulk={isBulk} tableColumns={tableColumns} query={ query } />
                        </div>
                    </div>
                }
                return <div key={i} className={`flex gap-3 items-center w-full`}>
                    <div className='flex gap-2 justify-between items-center font-bold capitalize w-[200px]'>
                        <span>{ c.name }</span>
                        { 
                            (c.nullable === false || c.choices.length > 0) && <span><AiOutlineWarning className="text-tcareRuby text-xl" /></span>
                        }
                    </div>
                    <div className='flex gap-2 w-[600px]'>
                        { input }
                        {
                            isBulk &&
                            <Select placeholder="Select Column" allowClear style={{ minWidth: "200px"}} onChange={val => setTableColumn(n, val)}>
                                {
                                    tableColumns.map(tCol => (
                                        <Option value={tCol}>{tCol}</Option>
                                    ))
                                }
                            </Select>
                        }
                    </div>
                </div>
            })
        }
    </div>
}

export default BuildBlock