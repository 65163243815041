import tcareData from "../images/TCARE Symbol - White.svg"
import { BsBookFill } from "react-icons/bs"
import { SiMicrosoftazure } from "react-icons/si"
import { IoLogOutOutline } from "react-icons/io5"
import { NavLink } from "react-router-dom"
import { useContext } from "react"
import { AuthContext } from "./Auth"
import { useMsal } from "@azure/msal-react"

const SideBar = () => {

    const { setSessionData, setIsLoggedIn } = useContext(AuthContext)

    const { instance } = useMsal();

    const logout = () => {
        setSessionData('')
        localStorage.removeItem('session')
        localStorage.removeItem('token')
        setIsLoggedIn(false)
        instance.logoutRedirect({})
    }

    return (
        <div className="flex flex-col justify-between w-14">
            <div className="flex flex-col gap-5 pt-8 items-center">
                <NavLink to="/tcare" className={({ isActive }) => "text-white text-[25px] hover:bg-bluegray-500 cursor-pointer px-3 py-3 rounded-lg" + (isActive ? ' !bg-tcareRuby' : '')} title="TCARE Data">
                    <img alt="tcare" src={tcareData} width="25px" />
                </NavLink>
                <NavLink to="/moodle" className={({ isActive }) => "text-white text-[25px] hover:bg-bluegray-500 cursor-pointer px-3 py-3 rounded-lg" + (isActive ? ' !bg-tcareRuby' : '')} title="Learning Data">
                    <BsBookFill />
                </NavLink>
                <NavLink to="/azure" className={({ isActive }) => "text-white text-[25px] hover:bg-bluegray-500 cursor-pointer px-3 py-3 rounded-lg" + (isActive ? ' !bg-tcareRuby' : '')} title="Azure Data">
                    <SiMicrosoftazure />
                </NavLink>
            </div>
            <div className="flex flex-col gap-5 pb-6 items-center">
                <div className="text-white text-[30px] hover:bg-bluegray-500 cursor-pointer px-3 py-3 rounded-lg" title="Logout" onClick={logout}>
                    <IoLogOutOutline />
                </div>
            </div>
        </div>
    )
}

export default SideBar