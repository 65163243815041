import { ThemeProvider } from "@mui/material";
import { Suspense } from "react";
import AppRoutes from "./AppRoutes";
import theme from "./mui_theme"
import { Provider } from 'react-redux'
import { store } from "./store/store"
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink } from "@apollo/client";
import { onError } from '@apollo/client/link/error';
import AuthState from './components/Auth';
import { BrowserRouter } from "react-router-dom";
import uatLogo from "./images/uat.png" 

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
    headers: {
        ...headers,
        Authorization: localStorage.getItem('token') || null,
    }
    }));
    return forward(operation);
})

const logoutLink = onError(({ networkError }) => {
    if (networkError !== undefined && (networkError.statusCode === 401 || networkError.statusCode === 403)) {
        localStorage.removeItem('token')
        window.location.href = '/'
    }
})

const client = new ApolloClient({
    cache: new InMemoryCache(),
    // headers: {
    //     Authorization: localStorage.getItem('token')
    // }
    link: logoutLink.concat(authMiddleware).concat(httpLink)
});

function App() {
    return (
        <Suspense>
            <BrowserRouter>
                <ApolloProvider client={client}>
                    <AuthState>
                        <Provider store={store}>
                            <ThemeProvider theme={theme}>
                                <div className="App h-screen w-full bg-mainbg">
                                    {
                                        process.env.REACT_APP_ENV === "UAT" && <div className="fixed bottom-0 right-0 opacity-50 z-1">
                                            <img src={uatLogo} alt="UAT"/>
                                        </div>
                                    }
                                    <div className="z-2">
                                        <AppRoutes />
                                    </div>
                                </div>
                            </ThemeProvider>
                        </Provider>
                    </AuthState>
                </ApolloProvider>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
