import { useEffect, useMemo, useState } from "react"
import { IoMdClose } from "react-icons/io"
import { useDispatch, useSelector } from "react-redux"
import { setAddFormColumns, setAddFormObjectTree, setAddFormValues, setShowFullForm } from "../store/common/reducers"
import DragUpload from "./DragUpload"
import { usePapaParse } from 'react-papaparse';
import { buildObject, buildQueryParams, createInputObject, getFormValue, getMutationObject, getSchemaObject } from "../data/parseSchema"
import BuildBlock from "./BuildBlock"
import { Button } from "antd"
import { InitiaMutationQuery } from "../queries/azure"
import { gql, useMutation } from '@apollo/client';
import { arrangeMutationForm } from "../data/fieldOrder"

const BulkAdd = ({ create, onClose, title }) => {

    const [resultObject, setResultObject] = useState(null)
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [csvData, setCSVData] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [addingFor, setAddingFor] = useState(1)
    const [mutationQuery, setMutationQuery] = useState(InitiaMutationQuery)
    const [uploadedData, setUploadedData] = useState([])

    const [performMutation, { data, loading, error, reset }] = useMutation(mutationQuery, {
        onError: (e) => {
            console.log(e)
        }
    });

    const { addFormObjectTree, addFormValues, addFormColumns, fieldsData, displayDataTree, showFullForm } = useSelector(state => state.common)

    const dispatch = useDispatch()
    const { readString } = usePapaParse()

    const headings = useMemo(() => {
        if (csvData.length === 0) return []
        return csvData[0]
    }, [csvData])

    const close = () => {
        dispatch(setAddFormObjectTree([]))
        dispatch(setAddFormValues(null))
        dispatch(setAddFormColumns(null))
        if (typeof onClose === 'function') onClose()
    }

    const readCSVData = ev => {
        // setIsFileUploaded(true)
        readString(ev.target.result, {
            worker: true,
            complete: results => {
                setCSVData(results.data)
                setIsFileUploaded(true)
            }
        })
    }

    const fileUploaded = file => {
        const reader = new FileReader()
        reader.onload = readCSVData;
        reader.readAsText(file.originFileObj, 'UTF-8')
    }

    const createObject = () => {
        let o = getMutationObject(create)
        setResultObject(o.value)
        let i = createInputObject(o.args)
        dispatch(setShowFullForm(false))
        // dispatch(setAddFormObjectTree(o.args))
        dispatch(setAddFormObjectTree(arrangeMutationForm(o.args, create, 'root')))
        dispatch(setAddFormValues(i))
        dispatch(setAddFormColumns(i))
    }

    useEffect(() => {
        createObject()
    }, [create]) // eslint-disable-line

    const loopMutationTree = (name, tree, tableValue) => {
        let y = ''
        let valid = true
        tree.forEach((c, i) => {
            let n = `${name}${name !== '' ? '.' : ''}${c.name}`
            y += `${c.name}: `
            if (c.list && c.children.length > 0) {
                y += '[\n'
                let v = getFormValue(n, addFormValues)
                v.forEach((e, j) => {
                    let ne = `${n}.${j}`
                    y += '{\n'
                    let u = loopMutationTree(ne, c.children, tableValue)
                    valid = u.valid === false ? u.valid : valid
                    y += u.tree
                    y += `\n}${v.length > j + 1 ? ',' : ''}\n`
                })
                y += `\n]${tree.length > i + 1 ? ',' : ''}\n`
            } else if (c.children.length > 0) {
                y += '{\n'
                let u = loopMutationTree(n, c.children, tableValue)
                valid = u.valid === false ? u.valid : valid
                y += u.tree
                y += `\n}${tree.length > i + 1 ? ',' : ''}\n`
            } else if (c.list) {
                y += '[\n'
                let t = getFormValue(n, addFormColumns)
                let v = ''
                if (typeof t !== 'object' && t !== undefined && t !== null && t !== false && t !== true) {
                    v = tableValue[t].split(',').map(ru => ru.trim())
                } else {
                    v = getFormValue(n, addFormValues)
                }
                if (c.nullable === false && v.length === 0) valid = false
                v.forEach(e => {
                    if (e === null) {
                        if (c.type === 'String') e = null
                        else if (c.type === 'Int') e = null
                        else if (c.type === 'Boolean') e = false
                    }
                    y += c.type === 'String' && c.choices.length === 0 && e !== null ? `"${e}"` : e
                    y += `${v.length > i + 1 ? ',' : ''}\n`
                })
                y += `\n]${tree.length > i + 1 ? ',' : ''}\n`
            } else {
                let t = getFormValue(n, addFormColumns)
                let v = ''
                if (t !== undefined && t !== null && t !== false && t !== true && tableValue[t] !== undefined) {
                    v = tableValue[t]
                } else {
                    v = getFormValue(n, addFormValues)
                }
                if (c.nullable === false && (v === null || (typeof v === 'string' && v.trim() === ""))) valid = false
                if (v === null || (typeof v === 'string' && v.trim() === "")) {
                    if (c.type === 'String') v = null
                    else if (c.type === 'Int') v = null
                    else if (c.type === 'Boolean') v = false
                }
                y += c.type === 'String' && c.choices.length === 0 && v !== null ? `"${v.trim()}"` : v
                y += `${tree.length > i + 1 ? ',' : ''}\n`
            }
        })
        return { tree: y, valid }
    }

    const createMutationTree = (tableValue) => {
        let y = `mutation {\n`
        y += `${create} (\n`
        let r = loopMutationTree('', addFormObjectTree, tableValue)
        let valid = r.valid
        y += r.tree
        y += '\n) {\n'
        let q = buildObject([], resultObject.type)
        let qu = getSchemaObject(resultObject.type)
        let qy = ''
        Object.keys(qu.fields).forEach(e => {
            let c = qu.fields[e]
            qy += `${c.name}`
            if (c.type === 'Boolean' || c.type === 'Int' || c.type === 'String') {
                qy += `\n`
            } else {
                let params = buildQueryParams(c.type, 0, fieldsData, displayDataTree, q)
                qy += '{\n'
                qy += params
                qy += '\n}\n'
            }
        })
        y += qy
        y += '\n}'
        y += '\n}'
        return { tree: y, valid }
    }

    const loopSubmit = () => {
        if (!submitted) return
        for(let i = addingFor; i < csvData.length; i++) {
            let c = csvData[i]
            if (i !== addingFor) return
            let value = {}
            headings.forEach((e, j) => {
                value[e] = c[j]
            })
            let y = createMutationTree(value)
            console.log(y.tree)
            if (y.valid) {
                setMutationQuery(gql`
                    ${y.tree}
                `)
                setTimeout(() => {
                    performMutation()
                }, 500)
                break;
            } else {
                setUploadedData([...uploadedData, { status: false, data: {}, error: 'Fill all required Fields' }])
            }
        }
    }
    
    const traverseAndFlatten = (currentNode, target) => {
        for (var key in currentNode) {
            if (currentNode.hasOwnProperty(key)) {
                var newKey = key
                var value = currentNode[key];
                if (typeof value === "object") {
                    traverseAndFlatten(value, target)
                } else {
                    target[newKey] = value
                }
            }
        }
    }

    useEffect(() => {
        if (!loading) {
            let u = [...uploadedData]
            if (error !== undefined && error !== null) {
                u = [...uploadedData, { status: false, data: {}, error }]
            } else if (data !== undefined && data !== null) {
                var flatData = {}
                traverseAndFlatten(data, flatData)
                u = [...uploadedData, { status: true, data: flatData, error: '' }]
            }
            setUploadedData(u)
            reset()
            if(submitted && u.length === addingFor && addingFor < csvData.length - 1)
                setAddingFor(addingFor + 1)
        }
    }, [loading, error, data, reset]) // eslint-disable-line

    const submit = () => {
        setSubmitted(true)
    }

    useEffect(() => {
        loopSubmit()
    }, [addingFor, submitted]) // eslint-disable-line

    const tableHeadings = useMemo(() => {
        let headings = []
        let e = uploadedData.filter(c => c.status)
        if (e.length > 0) headings = Object.keys(e[0].data).filter(c => !c.startsWith('__'))
        return headings
    }, [uploadedData])

    return (
        <div className="fixed top-0 bg-black bg-opacity-40 right-0 bottom-0 left-0 flex justify-center items-center z-10">
            <div className='flex flex-col relative bg-bluegray-200 p-3 rounded min-w-[60%] max-w-[90%] max-h-[90%]'>
                <div className='flex justify-between items-center pl-2'>
                    <div className='text-xl text-tcareDarkPurple font-bold'>
                        {title}
                    </div>
                    <div className='cursor-pointer bg-bluegray-200 rounded-full p-1' onClick={close}>
                        <IoMdClose size="20px" className='text-red-600' />
                    </div>
                </div>
                <div className='mt-4 px-3 pb-6 overflow-y-auto new-item-block'>
                    {
                        !isFileUploaded ?
                            <DragUpload onFileUpload={file => fileUploaded(file)} />
                            : !submitted ?
                                <div className="">
                                    <div className="overflow-y-auto new-item-block">
                                        <BuildBlock blocks={addFormObjectTree} name="" isBulk={true} tableColumns={headings} />
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="text-lg font-bold text-tcarePurple">
                                        Added <span className="text-tcareRuby">{uploadedData.length}</span> of <span className="text-tcareRuby">{csvData.length - 1}</span>
                                    </div>
                                    <div className="mt-3">
                                        <table className="w-full">
                                            <thead>
                                                <th className="border border-bluegray-500 px-3 py-2 bg-bluegray-400 text-white">Row</th>
                                                <th className="border border-bluegray-500 px-3 bg-bluegray-400 text-white">Error</th>
                                                {
                                                    tableHeadings.map(c => (
                                                        <th key={c} className="border border-bluegray-400 px-3 bg-bluegray-400 text-white">{c}</th>
                                                    ))
                                                }
                                            </thead>
                                            <tbody>
                                                {
                                                    uploadedData.map((c, i) => (
                                                        <tr key={i}>
                                                            <td className="border border-bluegray-400 px-3 bg-white">{i + 1}</td>
                                                            <td className="border border-bluegray-400 px-3 bg-white">{c.error.toString()}</td>
                                                            {
                                                                tableHeadings.map((e, j) => (
                                                                    <td key={j} className="border border-bluegray-400 px-3 bg-white">{c.data && c.data[e] !== undefined ? c.data[e].toString() : ''}</td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                    }
                </div>
                {
                    (isFileUploaded && !submitted) &&
                    <div className='flex justify-between py-4 pr-4'>
                        <Button className='!text-blue-500 !bg-transparent font-bold px-6 border-0' onClick={() => dispatch(setShowFullForm(!showFullForm))}>Show {showFullForm ? "Less" : "More"}</Button>
                        <Button className='!bg-tcareRuby !text-white font-bold px-6 border-0' onClick={submit}>Submit</Button>
                    </div>
                }
            </div>
        </div>
    )
}

export default BulkAdd