import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Route, Routes } from "react-router-dom"
import { getSchemaObject } from "./data/parseSchema"
import Dashboard from "./pages/Dashboard"
import { setFieldsData } from "./store/common/reducers"
import defaultFields from "./data/defaultFields.json"
import Moodle from "./pages/Moodle"
import Azure from "./pages/Azure"
import Tcare from "./pages/Tcare"
import Login from "./pages/Login"

const AppRoutes = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        console.log('redoing initial setup')
        let localObject = localStorage.getItem('fieldsData')
        localObject = localObject !== null ? JSON.parse(localObject) : {}
        let defaultObject = defaultFields
        let queries = getSchemaObject('all')
        let retObj = {}
        Object.keys(queries).forEach(c => {
            let r = queries[c]
            if(retObj[c] === undefined) retObj[c] = {}
            Object.keys(r.fields).forEach((e, i) => {
                if(e === "pageNumber" || e === 'pageSize') {
                    retObj[c][e] = true
                } else if(e === "join") {
                    retObj[c][e] = false
                } else {
                    retObj[c][e] = localObject[c] !== undefined && localObject[c][e] !== undefined ? localObject[c][e] : defaultObject[c] !== undefined && defaultObject[c][e] !== undefined ? defaultObject[c][e] : i < 8
                }
            })
        })
        dispatch(setFieldsData(retObj))
    }, [dispatch])

    return (
        
            <Routes>
                <Route path="/" exact element={ <Login /> } />
                <Route path="/tcare" exact element={ <Tcare /> } />
                <Route path="/moodle" exact element={ <Moodle /> } />
                <Route path="/azure" exact element={ <Azure /> } />
                <Route path="/home" exact element={ <Dashboard /> } />
            </Routes>
    )
}

export default AppRoutes