import SideBar from "../components/SideBar"
import TopBar from "../components/TopBar"

const Dashboard = () => {


    return (
        <div className="flex flex-col h-full">
            <TopBar />
            <div className="flex flex-1 gap-5">
                <SideBar />

            </div>
        </div>
    )
}

export default Dashboard