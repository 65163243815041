import React, { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react"
import { useLayoutEffect } from "react"
import { useNavigate, useLocation } from 'react-router-dom';
import { AzureUserByEmail } from '../queries/azure';
import { useLazyQuery } from '@apollo/client';
import { loginRequest } from "../authConfig";

export const AuthContext = React.createContext();
let gotToken = false;

const AuthState = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(true)
    const [isChecked, setIsChecked] = useState(false)
    const [sessionData, setSessionData] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { instance, accounts } = useMsal();
    const navigate = useNavigate()
    const location = useLocation()

    const [getData] = useLazyQuery(AzureUserByEmail);
    
    useEffect(() => {
        if(!isLoggedIn && location.pathname !== '/login') navigate('/')
        else if(isLoggedIn && location.pathname === '/') navigate('/tcare')
    }, [isLoggedIn]) // eslint-disable-line

    instance.addEventCallback((message) => {
        console.log(message)
        // Update UI or interact with EventMessage here
        if (message.eventType === 'msal:handleRedirectStart') {
            setIsLoading(true)
        }
        if (message.eventType === 'msal:acquireTokenSuccess') {
            gotToken = true
        }
        if (message.eventType === 'msal:handleRedirectEnd') {
            if(!gotToken)
                setIsLoading(false)
        }
    });

    useLayoutEffect(() => {
        instance.handleRedirectPromise()
            .then((s) => {
                if (s !== null && s.account != null) {
                    setSessionData(s.account.name);
                    localStorage.setItem('session', s.account.name);
                    localStorage.setItem('token', `Bearer ${s.accessToken}`)
                    console.log('success', s);
                    setIsLoggedIn(true)
                    setIsLoading(false)
                    getData()
                }
            })
            .catch((a) => {
                console.log('err', a);
                setIsLoading(false)
            });
    }, [instance]); // eslint-disable-line


    useLayoutEffect(() => {
        if(!isChecked) return
        let token = localStorage.getItem('token')
        console.log(token)
        if(token) {
            setIsLoggedIn(true)
            setSessionData(localStorage.getItem('session'))
        } else {
            setIsLoggedIn(false)
        }
    }, [isChecked])

    const refreshToken = () => {
        let token = localStorage.getItem('token')
        if(accounts.length <= 0 || token === null || token === undefined || token === '') {
            return
        }
        let userDetails = JSON.parse(atob(token.split('.')[1]));
        if(userDetails.exp - Math.floor(Date.now()/1000) < 1000) {
            loginRequest.account = instance.getAccountByHomeId(accounts[0].homeAccountId)
            instance.acquireTokenSilent(loginRequest)
                .then(response => {
                    console.log(response)
                    setSessionData(response.account.name);
                    console.log(response)
                    localStorage.setItem('session', response.account.name);
                    localStorage.setItem('token', `Bearer ${response.accessToken}`)
                })
        }
    }

    useEffect(() => {
        let token = localStorage.getItem('token')
        if(token) {
            getData()
            setSessionData(localStorage.getItem('session'))
        } else {
            setIsChecked(true)
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        const interval = setInterval(() => {
            refreshToken()
        }, 1000 * 60)

        return () => {
            clearInterval(interval)
        }
    }, [accounts]) // eslint-disable-line

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, sessionData, setSessionData }}>
            { isLoading ? null : children }
        </AuthContext.Provider>
    )
}

export default AuthState