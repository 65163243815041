const order = {
    "Organization": ["organizationId", "organizationName", "address", "phone", "licenses", "appVersion", "status", "createdAt", "createdById", "modifiedAt", "modifiedById", "parentOrganizations", "childOrganizations"],
    "Address": ["address1", "address2", "city", "state", "postalCode", "coutryCode"],
    "AssistUser": ["pplId", "email", "firstName", "middleName", "lastName", "preferredName", "address", "organization", "status", "deployment", "languagePreference", "createdAt", "createdById", "modifiedAt", "modifiedById"]
}

const mutationOrder = {
    "getOrCreateOrganization": {
        root: ["deployments", "organization"],
        organization: ["organizationName", "address", "phone", "licenses", "appVersion", "status", "parentOrganizationName"],
        address: ["address1", "address2", "city", "state", "postalCode", "countryCode"]
    }
}

export const fieldActions = {
    "Organization": [
        {
            name: "Add User to Organization",
            belongsTo: "user",
            query: "getOrCreateAssistUser",
            fields: {
                organizationName: "organizationName"
            }
        },
        {
            name: "Add Child Org",
            belongsTo: "user",
            query: "getOrCreateOrganization",
            fields: {
                parentOrganizationName: "organizationName"
            }
        }
    ],
    "AssistUser": [
        {
            name: "Change TCARE User Email",
            belongsTo: "user",
            query: "changeTcareUserEmail",
            fields: {
                oldEmail: "email"
            },
            disabled: ["oldEmail"]
        },
        {
            name: "Activate TCARE User",
            belongsTo: "user",
            query: "activateTcareUser",
            fields: {
                email: "email"
            },
            condition: {
                left: "status",
                operator: "!=",
                right: "ACTIVE"
            },
            disabled: ["email"]
        },
        {
            name: "Disable TCARE User",
            belongsTo: "user",
            query: "disableTcareUser",
            fields: {
                email: "email"
            },
            condition: {
                left: "status",
                operator: "!=",
                right: "DEACTIVATED"
            },
            disabled: ["email"]
        },
        {
            name: "Update TCARE User",
            belongsTo: "user",
            query: "updateTcareUser",
            fields: {
                email: "email"
            },
            disabled: ["email"],
            dataQueryFields: [
                {replace: '$email', value: 'email'}
            ],
            dataQueryName: "assistUserByEmail",
            dataQuery: `query {
                assistUserByEmail(email: "$email",
                    deployments: [
                    
                    ]
                )  {
                    address {
                        address1
                        address2
                        city
                        countryCode
                        postalCode
                        state
                    }
                    email
                    firstName
                    languagePreference
                    lastName
                    middleName
                    organization {
                        organizationName
                    }
                    preferredName
                    roles
                    status    
                }
            }`
        }
    ]
}

export const arrangeTitles = (titles, name) => {
    let keys = [...titles]
    let ordered = []
    if(order[name] !== undefined) {
        order[name].forEach(c => {
            if(keys.includes(c)) {
                ordered.push(c)
                keys = keys.filter(r => r !== c)
            }
        })
    }
    keys.forEach(c => {
        ordered.push(c)
    })
    return ordered
}

export const arrangeFieldOrder = (items) => {
    if(typeof items === 'object' && Array.isArray(items)) {
        return items.map(c => {
            return arrangeFieldOrder(c)
        })
    } else if(typeof items === 'object' && items !== null) {
        let name = items['__typename']
        if(name !== undefined) {
            let e = {}
            let keys = Object.keys(items)
            let ordered = []
            if(order[name] !== undefined) {
                order[name].forEach(c => {
                    if(keys.includes(c)) {
                        ordered.push(c)
                        keys = keys.filter(r => r !== c)
                    }
                })
            }
            keys.forEach(c => {
                ordered.push(c)
            })
            ordered.forEach(c => {
                e[c] = arrangeFieldOrder(items[c])
            })
            return e
        }
    }
    return items
}

export const arrangeMutationForm = (fields, name, type) => {
    let e = [...fields]
    let keys = fields.map(c => c.name)
    let ordered = []
    let add_more_true = false
    if(mutationOrder[name] !== undefined && mutationOrder[name][type] !== undefined) {
        add_more_true = true
        mutationOrder[name][type].forEach(c => {
            if(keys.includes(c)) {
                let r = e.find(t => t.name === c)
                if(r && r.children.length > 0) {
                    r.children = arrangeMutationForm(r.children, name, r.name)
                }
                r.more = false
                ordered.push(r)
                keys = keys.filter(t => t !== c)
            }
        })
    }
    keys.forEach(c => {
        let r = e.find(t => t.name === c) 
        r.more = add_more_true ? true : false
        ordered.push(r)
    })
    return ordered
}