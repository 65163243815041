import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux';
import { arrangeMutationForm } from '../data/fieldOrder';
import { buildObject, buildQueryParams, createInputObject, getFormValue, getMutationObject, getSchemaObject } from '../data/parseSchema'
import { AzureUserByEmail, InitiaMutationQuery } from '../queries/azure';
import { setAddFormObjectTree, setAddFormValues, setOrgNames, setPrefillValues, setShowFullForm } from '../store/common/reducers';
import BuildBlock from './BuildBlock';

const NewItem = ({ create, onClose, title, query= {} }) => {

    const [resultObject, setResultObject] = useState(null)
    const [showError, setShowError] = useState(false)
    const [mutationQuery, setMutationQuery] = useState(InitiaMutationQuery)
    const [mutationResult, setMutationResult] = useState(null)
    const [showMutationError, setShowMutationError] = useState(false)
    const [mutationError, setMutationError] = useState('')
    const [dataQuery, setDataQuery] = useState(AzureUserByEmail)
    const [runcreate, setRuncreate] = useState(false)

    const [performMutation, { data, loading, error, reset }] = useMutation(mutationQuery);
    const [getData, { loading: qLoading, data: qData }] = useLazyQuery(dataQuery, {

        fetchPolicy: 'network-only', // Doesn't check cache before making a network request
      
      });

    const { data: orgQueryNames } = useQuery(gql`
        query {
            organizations(
                pageable: { pageNumber: 0, pageSize: 1000, sorting: { orders: [] } }
                filter: { active: true, orgIds: [], orgNames: [], states: [] }
            ) {
                organizations {
                    organizationName
                }
                pageNumber
                pageSize
                totalOrganizations
            }
            }
        `);

    const { addFormObjectTree, addFormValues, fieldsData, displayDataTree, showFullForm, prefillValues } = useSelector(state => state.common)

    const dispatch = useDispatch()

    useEffect(() => {
        if(orgQueryNames === undefined) return
        dispatch(setOrgNames(orgQueryNames.organizations.organizations.map(c => c.organizationName)))
    }, [orgQueryNames, dispatch])

    useEffect(() => {
        if(showError) {
            setShowError(true)
            setTimeout(() => {
                setShowError(false)
            }, 5000)
        }
    }, [showError])

    useEffect(() => {
        if(showMutationError) {
            setShowMutationError(true)
            setTimeout(() => {
                setMutationError('')
                setShowMutationError(false)
            }, 5000)
        }
    }, [showMutationError])

    const close = () => {
        dispatch(setAddFormObjectTree([]))
        dispatch(setAddFormValues(null))
        if (typeof onClose === 'function') onClose()
    }

    const prefillFormValues = (formVals) => {
        if(typeof formVals === 'object' && !Array.isArray(formVals) && formVals !== null) {
            for(var e in formVals) {
                if(prefillValues[e] !== undefined) {
                    formVals[e] = prefillValues[e]
                }
                formVals[e] = prefillFormValues(formVals[e])
            }
        }
        return formVals
    }
    
    const flatObject = (obj) => {
        var o = {}
        if(typeof obj === "object") {
            Object.keys(obj).forEach(c => {
                if(typeof obj[c] === 'object' && Array.isArray(obj[c])) {
                    o[c] = obj[c]
                } else if(typeof obj[c] === 'object' && obj[c] !== null && obj[c] !== undefined) {
                    o = {...o, ...flatObject(obj[c])}
                } else o[c] = obj[c]
            })
        }
        return o
    }

    const createObject = () => {
        let o = getMutationObject(create)
        setResultObject(o.value)
        let i = createInputObject(o.args)
        dispatch(setShowFullForm(false))
        // dispatch(setAddFormObjectTree(o.args))
        dispatch(setAddFormObjectTree(arrangeMutationForm(o.args, create, 'root')))
        dispatch(prefillFormValues(setAddFormValues(i)))
        dispatch(setPrefillValues({}))
    }

    const loopMutationTree = (name, tree) => {
        let y = ''
        let valid = true
        tree.forEach((c, i) => {
            let n = `${name}${name !== '' ? '.' : ''}${c.name}`
            y += `${c.name}: `
            if (c.list && c.children.length > 0) {
                y += '[\n'
                let v = getFormValue(n, addFormValues)
                v.forEach((e, j) => {
                    let ne = `${n}.${j}`
                    y += '{\n'
                    let u = loopMutationTree(ne, c.children)
                    valid = u.valid === false ? u.valid : valid
                    y += u.tree
                    y += `\n}${v.length > j + 1 ? ',' : ''}\n`
                })
                y += `\n]${tree.length > i + 1 ? ',' : ''}\n`
            } else if (c.children.length > 0) {
                y += '{\n'
                let u = loopMutationTree(n, c.children)
                valid = u.valid === false ? u.valid : valid
                y += u.tree
                y += `\n}${tree.length > i + 1 ? ',' : ''}\n`
            } else if (c.list) {
                y += '[\n'
                let v = getFormValue(n, addFormValues)
                if(c.nullable === false && v.length === 0) valid = false
                v.forEach(e => {
                    if(e === null) {
                        if(c.type === 'String') e = null
                        else if(c.type === 'Int') e = null
                        else if(c.type === 'Boolean') e = false
                    }
                    y += c.type === 'String' && c.choices.length === 0 && e !== null ? `"${e}"` : e
                    y += `${v.length > i + 1 ? ',' : ''}\n`
                })
                y += `\n]${tree.length > i + 1 ? ',' : ''}\n`
            } else {
                let v = getFormValue(n, addFormValues)
                if(c.nullable === false && (v === null || (typeof v === 'string' && v.trim() === ""))) valid = false
                if(v === null || (typeof v === 'string' && v.trim() === "")) {
                    if(c.type === 'String') v = null
                    else if(c.type === 'Int') v = null
                    else if(c.type === 'Boolean') v = false
                }
                y += c.type === 'String' && c.choices.length === 0 && v !== null ? `"${v.trim()}"` : v
                y += `${tree.length > i + 1 ? ',' : ''}\n`
            }
        })
        return {tree: y, valid}
    }

    const createMutationTree = () => {
        let y = `mutation {\n`
        y += `${create} (\n`
        let r = loopMutationTree('', addFormObjectTree)
        let valid = r.valid
        y += r.tree
        y += '\n) {\n'
        let q = buildObject([], resultObject.type)
        let qu = getSchemaObject(resultObject.type)
        let qy = ''
        Object.keys(qu.fields).forEach(e => {
            let c = qu.fields[e]
            qy += `${c.name}`
            if(c.type === 'Boolean' || c.type === 'Int' || c.type === 'String') {
                qy += `\n`
            } else {
                let params = buildQueryParams(c.type, 0, fieldsData, displayDataTree, q)
                qy += '{\n'
                qy += params
                qy += '\n}\n'
            }
        })
        y += qy
        y += '\n}'
        y += '\n}'
        return {tree: y, valid}
    }

    const submit = () => {
        let y = createMutationTree()
        console.log(y.tree)
        setShowError(!y.valid)
        if(y.valid) {
            setMutationQuery(gql`
                ${y.tree}
            `)
            setTimeout(() => {
                performMutation()
            }, 500)
        }
    }

    useEffect(() => {
        createObject()
    }, [create, runcreate]) // eslint-disable-line

    useEffect(() => {
        if(query.dataQuery !== undefined) {
            setDataQuery(gql`
                ${query.dataQuery}
            `)
            getData()
        }
    }, [query]) // eslint-disable-line

    useEffect(() => {
        if(!qLoading && qData !== undefined && qData[query.dataQueryName] !== undefined && qData[query.dataQueryName] != null) {
            if(Array.isArray(qData[query.dataQueryName])) {
                dispatch(setPrefillValues(flatObject(qData[query.dataQueryName][0])))
            } else {
                dispatch(setPrefillValues(flatObject(qData[query.dataQueryName])))
            }
            setRuncreate(!runcreate)
        }
    }, [qLoading, qData]) // eslint-disable-line

    useEffect(() => {
        if(!loading) {
            if(error !== undefined && error !== null) {
                setMutationError(error.message)
                setShowMutationError(true)
            } else if(data !== undefined && data !== null) {
                setMutationResult(data)
            }
            reset()
        }
    }, [loading, error, data, reset])

    const BuildResultBlock = useCallback(({ values }) => {
        if(values === null) return null
        if(typeof values === 'object') {
            return <div>
                {
                    Object.keys(values).map((c, i) => {
                        if(c.startsWith('__')) return null
                        if(typeof values[c] === 'object') {
                            return <div key={i} className="flex gap-3">
                                {/* <div className=''>{c}</div> */}
                                <BuildResultBlock values={values[c]} />
                            </div>
                        }
                        return <div key={c} className="flex gap-3">
                            <div className='min-w-[150px]'>{c}</div>
                            <div className='font-bold'>{values[c].toString()}</div>
                        </div>
                    })
                }
            </div>
        } else {
            return <div className='font-bold'>{values}</div>
        }
    }, [])
    
    return (
        <div className="fixed top-0 bg-black bg-opacity-40 right-0 bottom-0 left-0 flex justify-center items-center z-10">
            <div className='flex flex-col relative bg-bluegray-200 p-3 rounded min-w-[60%] max-w-[90%] max-h-[90%]'>
                <div className='flex justify-between items-center pl-2'>
                    <div className='text-xl text-tcareDarkPurple font-bold'>
                        {title}
                    </div>
                    { showError &&
                        <div className='flex items-center gap-2 text-tcareRuby border-2 animate-pulse border-tcareRuby px-6 rounded text-lg'>
                            <span><AiOutlineWarning className='text-xl' /></span>
                            <span className='font-bold'>Fill all Required Fields</span>
                        </div>
                    }
                    { showMutationError  &&
                        <div className='flex items-center gap-2 text-tcareRuby border-2 animate-pulse border-tcareRuby px-6 rounded text-lg'>
                            <span><AiOutlineWarning className='text-xl' /></span>
                            <span className='font-bold'>Unable to Create ({mutationError})</span>
                        </div>
                    }
                    <div className='cursor-pointer bg-bluegray-200 rounded-full p-1' onClick={close}>
                        <IoMdClose size="20px" className='text-red-600' />
                    </div>
                </div>
                <div className='mt-3 px-3 pb-4 overflow-y-auto new-item-block'>
                    {
                        (mutationResult === null || mutationResult === undefined) ? 
                            <BuildBlock blocks={addFormObjectTree} name="" query={query} />
                        : <div>
                            <BuildResultBlock values={mutationResult} />
                        </div>
                    }
                </div>
                {
                    (mutationResult === null || mutationResult === undefined) ?
                        <div className='flex justify-between py-4 pr-4'>
                            <Button className='!text-blue-500 !bg-transparent font-bold px-6 border-0' onClick={() => dispatch(setShowFullForm(!showFullForm))}>Show {showFullForm ? "Less" : "More"}</Button>
                            <Button className='!bg-tcareRuby !text-white font-bold px-6 border-0' onClick={submit}>Submit</Button>
                        </div>
                    :
                        <div className='py-4 text-right pr-4'>
                            <Button className='!bg-blue-400 !text-white font-bold px-6 border-0' onClick={() => setMutationResult(null)}>Back to Form</Button>
                        </div>
                }
                { loading && <div className="absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center bg-black bg-opacity-60">
                        <Spin tip="Loading..."></Spin>
                    </div>
                }
            </div>
        </div>
    )
}

export default NewItem