import { gql, useLazyQuery } from "@apollo/client"
import { Button, Spin, Table } from "antd"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { arrangeTitles, fieldActions } from "../data/fieldOrder"
import { buildObject, buildQueryParams, createInputObject, getFormValue, getQueryObject, getSchemaObject } from "../data/parseSchema"
import { PageMutations } from "../data/queries"
import { AzureUserByEmail } from "../queries/azure"
import { setDisplayDataTree, setLastQuery, setPageLoading, setPrefillValues, setSearchArgs, setSearchValues } from "../store/common/reducers"
import BuildSearchBlock from "./BuildSearchBlock"
import NewItem from "./NewItem"
import FieldActions from "./FieldActions"

let pageSizeUp = 10

const DataFlow = ({ query }) => {

    const [initialObject, setInitialObject] = useState(null)
    const [queryObject, setQueryObjects] = useState([])
    const [dataQuery, setDataQuery] = useState(AzureUserByEmail)
    const [waitForData, setWaitForData] = useState(false)
    const [highlight, setHighlight] = useState(null)
    const [showNewItem, setShowNewItem] = useState(false)
    const [createNewMutation, setCreateNewMutation] = useState({belongsTo: null, method: null, query: null})

    const workarea = useRef(null)

    const dispatch = useDispatch()
    const { fieldsData, displayDataTree, searchArgs, lastQuery, searchValues } = useSelector(state => state.common)

    const loopDataTree = (name, tree) => {
        let y = ''
        let valid = true
        tree.forEach((c, i) => {
            let n = `${name}${name !== '' ? '.' : ''}${c.name}`
            if (c.list && c.children.length > 0) {
                y += `${c.name}: `
                y += '[\n'
                let v = getFormValue(n, searchValues)
                v.forEach((e, j) => {
                    let ne = `${n}.${j}`
                    y += '{\n'
                    let u = loopDataTree(ne, c.children)
                    valid = u.valid === false ? u.valid : valid
                    y += u.tree
                    y += `\n}${v.length > j + 1 ? ',' : ''}\n`
                })
                y += `\n]${tree.length > i + 1 ? ',' : ''}\n`
            } else if (c.children.length > 0) {
                y += `${c.name}: `
                y += '{\n'
                let u = loopDataTree(n, c.children)
                valid = u.valid === false ? u.valid : valid
                y += u.tree
                y += `\n}${tree.length > i + 1 ? ',' : ''}\n`
            } else if (c.list) {
                y += `${c.name}: `
                y += '[\n'
                let v = getFormValue(n, searchValues)
                if(c.nullable === false && v.length === 0) valid = false
                v.forEach(e => {
                    if(e !== null) {
                        y += c.type === 'String' && c.choices.length === 0 ? `"${e}"` : e
                        y += `${v.length > i + 1 ? ',' : ''}\n`
                    }
                })
                y += `\n]${tree.length > i + 1 ? ',' : ''}\n`
            } else {
                let v = getFormValue(n, searchValues)
                if(c.nullable === false && (v === null || v === "")) valid = false
                if(v !== null) {
                    y += `${c.name}: `
                    y += c.type === 'String' && c.choices.length === 0 && v !== null ? `"${v.trim()}"` : v
                    y += `${tree.length > i + 1 ? ',' : ''}\n`
                }
            }
        })
        return {tree: y, valid}
    }

    const buildDataQuery = () => {
        if(initialObject === null) return
        let a = ''
        let r = loopDataTree('', searchArgs)
        if(searchArgs.length > 0 && r.tree.trim() !== '') a = '('
        let valid = true
        if(searchArgs.length > 0 && r.tree.trim() !== '') a += r.tree
        if(!valid) return
        if(searchArgs.length > 0 && r.tree.trim() !== '') a += ') '
        let params = buildQueryParams(initialObject.type, 0, fieldsData, displayDataTree, queryObject)
        let q = `query {
            ${query}${a} {
                ${params}
            }
        }`
        console.log(q)
        setDataQuery(gql`
            ${q}
        `)
    }

    const [getData, { loading, data, error, refetch }] = useLazyQuery(dataQuery, {

        fetchPolicy: 'network-only', // Doesn't check cache before making a network request
      
      });

    const initSearch = () => {
        buildDataQuery()
        getData()
        setTimeout(() => {
            setWaitForData(false)
        }, 1000)
    }

    useEffect(() => {
        let object = getQueryObject(query)
        if(lastQuery !== query) {
            dispatch(setSearchArgs([]))
            dispatch(setLastQuery(query))
            let val = createInputObject(object.args)
            if(val.pageable !== undefined) {
                val.pageable.pageNumber = 0
                val.pageable.pageSize = 10
            }
            dispatch(setSearchValues(val))
            dispatch(setSearchArgs(object.args))
            dispatch(setDisplayDataTree([{query: object.value, value: {}}]))
        }
        setInitialObject(object.value)
        let ob = buildObject([], object.value?.type)
        setQueryObjects(ob)
    }, [query, lastQuery, dispatch])

    // const updateValue = (name, value) => {
    //     dispatch(setSearchArgs(searchArgs.map(c => {
    //         let e = {...c}
    //         if(c.name === name) e.value = value
    //         return e
    //     })))
    // }

    const getDataToRender = (tree) => {
        let r = data
        if(r === null || r === undefined) return null
        for(let e of tree) {
            if(e.value.index !== undefined) {
                r = r[e.value.index]
            }
            if(r[e.query] === undefined) return null
            r = r[e.query]
        }
        return r
    }
    
    const attachData = (i, col, u, index) => {
        setWaitForData(true)
        let q = getSchemaObject(u.type)
        let val = {index, col}
        if(q !== undefined  && q.fields !== undefined && q.fields.pageNumber !== undefined && q.fields.pageSize !== undefined) val = {...val, value: 0, pageSize: 10}
        dispatch(setDisplayDataTree([...displayDataTree.slice(0, i + 1), {query: {...u}, value: val}]))
    }

    const attachObjData = (i, col, u) => {
        setWaitForData(true)
        let q = getSchemaObject(u.type)
        let val = {col}
        if(q !== undefined  && q.fields !== undefined && q.fields.pageNumber !== undefined && q.fields.pageSize !== undefined) val = {...val, value: 0, pageSize: 10}
        dispatch(setDisplayDataTree([...displayDataTree.slice(0, i + 1), {query: {...u}, value: val}]))
    }

    const setPageNum = (page, displayIndex) => {
        setWaitForData(true)
        if(displayIndex <= 1) {
            // dispatch(setSearchArgs([...searchArgs.map(c => {
            //     let e = {...c}
            //     if(c.name === 'pageable') {
            //         e.value = page - 1
            //         e.pageSize = pageSizeUp
            //     }
            //     return e
            // })]))
            // dispatch(setSearchValues({...searchValues, pageable: {...searchValues.pageable, pageNumber: page - 1, pageSize: pageSizeUp}}))
            // dispatch(setDisplayDataTree([...displayDataTree.slice(0, displayIndex + 1)]))
        } else {
            dispatch(setDisplayDataTree([...displayDataTree.slice(0, displayIndex + 1)].map((c, i) => {
                let e = {...c}
                if(i === displayIndex - 1) e = {...e, value: {...e.value, value: page - 1, pageSize: pageSizeUp}}
                return e
            })))
        }
    }

    const setPageSize = (curr, size) => {
        pageSizeUp = size
    }

    const handleTableChange = (pagination, filter, sorter, displayIndex) => {
        if(displayIndex > 1) return
        let sortVal = []
        if(typeof sorter === 'object' && Array.isArray(sorter)) {
            sortVal = sorter.map(c => ({direction: c.order === 'ascend' ? 'ASC' : 'DESC', property: c.columnKey}))
        } else if(typeof sorter === 'object') {
            if(sorter.order !== undefined) {
                sortVal = [{direction: sorter.order === 'ascend' ? 'ASC' : 'DESC', property: sorter.columnKey}]
            }
        }
        if(searchValues.pageable !== undefined) {
            dispatch(setSearchValues({...searchValues, pageable: {...searchValues.pageable, pageNumber: pagination.current - 1, pageSize: pagination.pageSize, sorting: {...searchValues.pageable.sorting, orders: sortVal}}}))
            dispatch(setDisplayDataTree([...displayDataTree.slice(0, displayIndex + 1)]))
        }
    }

    const createNewFromObject = (q, data) => {
        let query = JSON.parse(JSON.stringify(q))
        let prefillValues = {}
        Object.keys(query.fields).forEach(c => {
            let t = data.find(y => y.name === query.fields[c])
            if(t) {
                prefillValues[c] = t.value
            }
        })
        dispatch(setPrefillValues(prefillValues))
        if(query.dataQuery !== undefined) {
            query.dataQueryFields.forEach(c => {
                query.dataQuery = query.dataQuery.replace(c.replace, prefillValues[c.value])
            })
        }
        setCreateNewMutation({belongsTo: query.belongsTo, method: query.query, query})
        setShowNewItem(true)
    }

    const createNewFromArray = (q, data) => {
        let query = JSON.parse(JSON.stringify(q))
        let prefillValues = {}
        Object.keys(query.fields).forEach(c => {
            let t = data[query.fields[c]]
            if(t) {
                prefillValues[c] = t
            }
        })
        dispatch(setPrefillValues(prefillValues))
        if(query.dataQuery !== undefined) {
            query.dataQueryFields.forEach(c => {
                query.dataQuery = query.dataQuery.replace(c.replace, prefillValues[c.value])
            })
        }
        setCreateNewMutation({belongsTo: query.belongsTo, method: query.query, query})
        setShowNewItem(true)
    }

    const closeAddItem = () => {
        setShowNewItem(false)
        setCreateNewMutation({belongsTo: null, method: null, query: null})
        buildDataQuery()
        setTimeout(() => {
            setWaitForData(true)
            dispatch(setDisplayDataTree([...displayDataTree]))
            refetch()
        }, 1000)
    }

    const roleColors = {
        "INSIGHTS": "bg-orange-500",
        "INSIGHTS_REDACTED": "bg-amber-500",
        "INSIGHTS_FULL": "bg-yellow-500",
        "DYNAMIC_INSIGHTS": "bg-lime-500",
        "DYNAMIC_INSIGHTS_REDACTED": "bg-green-500",
        "DYNAMIC_INSIGHTS_FULL": "bg-emerald-500",
        "SUPERADMIN": "bg-teal-500",
        "ADMIN": "bg-cyan-500",
        "CAREMANAGER": "bg-sky-500",
        "SCREENER": "bg-blue-500",
        "RDBAPI": "bg-indigo-500",
        "REFERRER": "bg-violet-500",
        "DYNAMIC_ADMIN": "bg-purple-500",
        "DYNAMIC_CM": "bg-orange-500",
        "DYNAMIC_SCR": "bg-amber-500",
        "DYNAMIC_REF": "bg-yellow-500",
        "DEVELOPER": "bg-lime-500",
    }

    const RenderData = () => {
        if(data === undefined || data[query] === undefined) return null
        let datafor = [{query, value: {}}]
        if(loading || waitForData) return <Spin tip="Loading..."></Spin>
        let nextPage = null
        let dataAr = []
        return <div className="flex gap-4">
            {
                displayDataTree.map((c, i) => {
                    if(i !== 0) datafor.push({query: c.query.name, value: c.value})
                    let r = getDataToRender(datafor)
                    dataAr.push(r)
                    if(r === null) return <div key={i} className={`bg-bluegray-600 text-white px-4 py-3 w-80 rounded data-object ${highlight === i ? 'border-2 border-tcareRuby' : ''}`}>No Data</div>
                    if(c.query.list) {
                        let columns = []
                        if(r.length > 0) {
                            columns = arrangeTitles(Object.keys(fieldsData[c.query.type]), c.query.type).map((col, sorti) => {
                                if(fieldsData[c.query.type][col]) {
                                    let tSorter = false
                                    let tSortOrder = ''
                                    if(i <= 1 && searchValues.pageable !== undefined) {
                                        tSorter = {multiple: sorti}
                                        let w = searchValues.pageable.sorting.orders.find(wi => wi.property === col)
                                        if(w) {
                                            tSortOrder = w.direction === 'ASC' ? 'ascend' : 'descend'
                                        }
                                    }
                                    let colVal = {title: col, dataIndex: col, key: col, sorter: tSorter, defaultSortOrder: tSortOrder, className: '!bg-bluegray-600 !border-bluegray-500 !text-white'}
                                    let u = fieldsData[queryObject.find(qw => qw.name === c.query.type).fields[col].type]
                                    if(u !== undefined) {
                                        colVal['render'] = (text, record, index) => <div className="flex gap-1">
                                            {col === "organization" && c.query.name === "assistUsers" ? text?.organizationName : ''}
                                            <Button size="small"  className={`${displayDataTree[i + 1]?.value?.col === col && displayDataTree[i + 1]?.value.index === index ? '!bg-tcareRuby text-white' : '!bg-tcareAqua text-black'} border-0`} onClick={() => attachData(i, col, queryObject.find(qw => qw.name === c.query.type).fields[col], index)}>View</Button>
                                        </div>
                                    }
                                    return colVal
                                }
                                return null
                            }).filter(c => c !== null)
                            if(fieldActions[c.query.type] !== undefined) {
                                let colVal = {title: 'Action', dataIndex: '', key: 'action', className: '!bg-bluegray-600 !border-bluegray-500 !text-white'}
                                colVal['render'] = (text, record, index) => <FieldActions fieldActions={fieldActions} c={c} record={record} createNewFromArray={createNewFromArray} />
                                columns.push(colVal)
                            }
                        }
                        let pagination = {}
                        if(nextPage !== null && i <= 1) {
                            let tot = +nextPage[Object.keys(nextPage).find(er => er.includes('total'))]
                            // let pagArg = searchArgs.find(cr => cr.name === 'pageable')
                            let pagArg = searchValues.pageable
                            if(pagArg)
                                pagination = {total: tot, pageSize: pagArg.pageSize, current: pagArg.pageNumber + 1, showQuickJumper: true, showSizeChanger: true, onChange: page => setPageNum(page, i), onShowSizeChange: (curr, size) => setPageSize(curr, size)}
                            nextPage = null
                        } else if(displayDataTree[i - 1]?.value?.pageSize !== undefined) {
                            let tot = +dataAr[dataAr.length - 2][Object.keys(nextPage).find(er => er.includes('total'))]
                            pagination = {total: tot, pageSize: displayDataTree[i - 1].value.pageSize, current: displayDataTree[i - 1].value.value + 1, showQuickJumper: true, showSizeChanger: true, onChange: page => setPageNum(page, i), onShowSizeChange: (curr, size) => setPageSize(curr, size)}
                        }
                        if(Object.keys(pagination).length === 0) pagination = false
                        r = r.map(icd => {
                            let copyC = {...icd}
                            for(let icdk in copyC) {
                                if(Array.isArray(copyC[icdk])) copyC[icdk] = <div className="flex gap-1 flex-wrap">
                                        {copyC[icdk].map(tg => <span className={`${roleColors[tg] || 'bg-violet-500'} rounded-full px-2 py-0.5 text-xs`}>{tg}</span>)}
                                    </div>
                            }
                            return copyC
                        })
                        return <div key={i} className="data-object">
                            <div className={`bg-bluegray-600 px-4 py-3 rounded ${highlight === i ? 'border-2 border-tcareRuby' : ''}`}>
                                <div className="text-white text-lg font-bold underline">{ c.query.type }</div>
                                <Table columns={columns} dataSource={r} showHeader={true} className="bg-bluegray-600" rowClassName="bg-bluegray-600 text-white" bordered={false} size="middle" tableLayout="auto" expandable={false} pagination={pagination} onChange={(pagination, filter, sorter) => handleTableChange(pagination, filter, sorter, i)} />
                            </div>
                        </div>
                    } else {
                        if(Object.keys(fieldsData[c.query.type]).includes('pageNumber') && Object.keys(fieldsData[c.query.type]).includes('pageSize')) {
                            nextPage = r
                        }
                        let idata = arrangeTitles(Object.keys(fieldsData[c.query.type]).filter(e => fieldsData[c.query.type][e]), c.query.type).map(e => {
                            let u = fieldsData[queryObject.find(qw => qw.name === c.query.type).fields[e].type]
                            if(u !== undefined) {
                                return {name: e, value: <Button size="small" className={`${ displayDataTree[i + 1]?.value?.col === e ? '!bg-tcareRuby text-white' : '!bg-tcareAqua text-black'} border-0`} onClick={() => attachObjData(i, e, queryObject.find(qw => qw.name === c.query.type).fields[e])}>View</Button>} 
                            }
                            return {name: e, value: (r[e] || "").toString()}
                        })
                        idata = idata.map(icd => {
                            if(Array.isArray(icd.value)) icd.value = icd.value.join(", ")
                            return icd
                        })
                        if(fieldActions[c.query.type] !== undefined) {
                            idata.push({name: 'Actions', value:
                                <div className="flex flex-col gap-2">
                                    {
                                        fieldActions[c.query.type].map(act => {
                                            return <Button key={act.name} size="small" className={`!bg-tcareAqua text-black border-0`} onClick={() => createNewFromObject(act, idata)}>{act.name}</Button>
                                        })
                                    }
                                </div>
                            })
                        }
                        let columns = [{title: 'Name', dataIndex: 'name', key: 'name', className: '!bg-bluegray-600 !border-bluegray-500 font-bold'}, {title: 'Value', dataIndex: 'value', key: 'value', className: '!bg-bluegray-600  !border-bluegray-500'}]
                        return <div key={i} className="data-object">
                            <div className={`bg-bluegray-600 px-4 py-3 rounded ${highlight === i ? 'border-2 border-tcareRuby' : ''}`}>
                                <div className="text-white text-lg font-bold underline">{ c.query.type }</div>
                                <Table columns={columns} dataSource={idata} pagination={false} showHeader={false} className="bg-bluegray-600" rowClassName="bg-bluegray-600 text-white" bordered={false} size="middle" tableLayout="auto" expandable={false} />
                            </div>
                        </div>
                    }
                })
            }
        </div>
    }

    // for pageable
    // useEffect(() => {
    //     let r = searchArgs.find(c => c.name === 'pageable')
    //     if(r && displayDataTree.length === 1) {
    //         let t = Object.keys(queryObject[0].fields).find(c => queryObject[0].fields[c].list)
    //         if(t) {
    //             dispatch(setDisplayDataTree([...displayDataTree, {query: {name: queryObject[0].fields[t].name, type: queryObject[0].fields[t].type, list: true, page: true}, value: {}}]))
    //         }
    //     }
    // }, [query, searchArgs]) // eslint-disable-line
    
    useEffect(() => {
        initSearch()
    }, [queryObject, displayDataTree]) // eslint-disable-line

    useEffect(() => {
        setWaitForData(loading)
        if(!loading && !waitForData) {
            focusItem(displayDataTree.length - 1)
        }
        if(loading || waitForData) dispatch(setPageLoading(true))
        else dispatch(setPageLoading(false))
    }, [loading, waitForData]) // eslint-disable-line

    const focusItem = (i, smooth) => {
        let e = workarea.current.querySelectorAll(`.data-object`)
        if(e[i] !== undefined) e = e[i]
        else return
        workarea.current.scrollTo({
            left: e.getBoundingClientRect().left + workarea.current.scrollLeft - 89,
            behavior: smooth ? 'smooth' : 'auto',
        })
        setHighlight(i)
    }

    useEffect(() => {
        if(highlight !== null) {
            setTimeout(() => {
                setHighlight(null)
            }, 1000)
        }
    }, [highlight])

    const clearDataTree = () => {
        if(searchValues.pageable !== undefined) {
            dispatch(setSearchValues({...searchValues, pageable: {...searchValues.pageable, pageNumber: 0}}))
        }
        dispatch(setDisplayDataTree([{query: initialObject, value: {}}]))
    }
    
    return (
        <div className="flex flex-col w-full flex-1">
            { searchArgs.filter(c => c.name !== 'pageable').length > 0 &&
                <div className="flex gap-4 mt-1 px-7 search-filter">
                    <div className="text-white text-lg font-bold">
                        Search:
                    </div>
                    {/* {
                        searchArgs.filter(c => c.name !== 'pageable').map((c, i) => (
                            <div key={i}>
                                <Input placeholder={c.name} value={c.value} onChange={val => updateValue(c.name, val.target.value)} bordered={false} className="!bg-bluegray-600 text-white" />
                            </div>
                        ))
                    } */}
                    <BuildSearchBlock blocks={searchArgs} name="" />
                    <Button className="!bg-tcareRuby !text-white border-0 font-bold" onClick={ clearDataTree }>Search</Button>
                </div>
            }
            {
                displayDataTree.length > 0 &&
                <div className="flex mt-1 gap-1 text-white px-5">
                    {
                        displayDataTree.map((c, i) => (
                            <div key={i} className="flex gap-1">
                                <div className="underline cursor-pointer" onClick={() => focusItem(i, true)}>{ c.query.type }</div>
                                {
                                    displayDataTree.length !== i + 1 && <div>/</div>
                                }
                            </div>
                        ))
                    }
                </div>
            }
            {/* <div className="text-white">
                { JSON.stringify(data) }
                { JSON.stringify(displayDataTree) }
            </div> */}
            <div className="data-workarea mt-4 px-5 overflow-auto min-w-full" ref={workarea}>
                {
                    loading || waitForData ? 
                        <div className="flex justify-center items-center w-full h-full">
                            <Spin tip="Loading..."></Spin>
                        </div> 
                    : error !== undefined && (displayDataTree.length <= 1) ?
                        <div className="bg-bluegray-600 text-white px-4 py-3 w-80 rounded data-object">No Data</div>
                    :
                        <RenderData />
                }
            </div>
            {
                showNewItem &&
                <NewItem create={ createNewMutation.method } query={ createNewMutation.query } onClose={ () => closeAddItem()} title={`${PageMutations[createNewMutation.belongsTo].find(c => c.value === createNewMutation.method)?.text}`} />
            }
        </div>
    )
}

export default DataFlow