import { createSlice } from '@reduxjs/toolkit'
import { commonState } from './store'

const commonReducer = createSlice({
    name: 'common',
    initialState: commonState,
    reducers: {
        setFieldsData: (state, action) => {
            localStorage.setItem('fieldsData', JSON.stringify(action.payload))
            state.fieldsData = action.payload
        },
        setDisplayDataTree: (state, action) => {
            state.displayDataTree = action.payload
        },
        setPageLoading: (state, action) => {
            state.pageLoading = action.payload
        },
        setSearchArgs: (state, action) => {
            state.searchArgs = action.payload
        },
        setSearchValues: (state, action) => {
            state.searchValues = action.payload
        },
        setLastQuery: (state, action) => {
            state.lastQuery = action.payload
        },
        setAddFormObjectTree: (state, action) => {
            state.addFormObjectTree = action.payload
        },
        setAddFormValues: (state, action) => {
            state.addFormValues = action.payload
        },
        setAddFormColumns: (state, action) => {
            state.addFormColumns = action.payload
        },
        setOrgNames: (state, action) => {
            state.orgNames = action.payload
        },
        setShowFullForm: (state, action) => {
            state.showFullForm = action.payload
        },
        setPrefillValues: (state, action) => {
            state.prefillValues = action.payload
        }
    }
})

export const { setFieldsData, setDisplayDataTree, setPageLoading, setSearchArgs, setLastQuery, setAddFormObjectTree, setAddFormValues, setSearchValues, setAddFormColumns, setOrgNames, setShowFullForm, setPrefillValues } = commonReducer.actions

export default commonReducer.reducer