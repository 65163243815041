import { useEffect } from "react"
import ReactFlow, { Controls, MarkerType, useEdgesState, useNodesState } from "react-flow-renderer";
import { buildObject, getQueryObject } from "../data/parseSchema"
import FlowNode from "./FlowNode";

const connectionLineStyle = { stroke: '#fff' };
const snapGrid = [20, 20];

const nodeTypes = {
    queryNode: FlowNode
}

const QueryFlow = ({ query }) => {

    const [nodesData, setNodesData, onNodesDataChange] = useNodesState([]);
    const [edgesData, setEdgesData, onEdgesDataChange] = useEdgesState([]);

    const createNodesEdges = (object) => {
        let x = 0
        let y = 0
        let maxY = 0
        let stack = 0
        let nodes = object.map((c, i) => {
            let l = Object.keys(c.fields).length
            let hei = (l * 30) + 100
            if (hei + y - 100 > maxY || stack > 2) {
                x = x + Math.floor((Math.random() * 200) + 300)
                y = Math.floor((Math.random() * 100) + 1)
                stack = 0
            } else {
                y = y + 40
                stack += 1
            }

            let node = {
                id: `${i + 1}`,
                type: 'queryNode',
                data: { label: c.name, data: c, nodes: object },
                style: { minWidth: '250px' },
                position: { x, y }
            }
            y = y + hei
            if (y > maxY) maxY = hei

            return node
        })
        let edges = []
        object.forEach((c, j) => {
            Object.keys(c.fields).forEach(e => {
                let r = c.fields[e]
                if (r.type !== 'Int' && r.type !== 'String' && r.type !== 'Boolean') {
                    let i = object.findIndex(t => t.name === r.type)
                    if (i < 0 || i === j) return
                    let edge = {
                        id: `e${j + 1}a-${i + 1}`,
                        source: `${j + 1}`,
                        target: `${i + 1}`,
                        sourceHandle: 'a',
                        animated: true,
                        style: { stroke: '#fff' },
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                        },
                    }
                    edges.push(edge)
                }
            })
        })
        return { nodes, edges }
    }

    useEffect(() => {
        let object = getQueryObject(query)
        let ob = buildObject([], object.value?.type)
        let { nodes, edges } = createNodesEdges(ob)
        setNodesData(nodes)
        setEdgesData(edges)
    }, [query]) // eslint-disable-line

    return (
        <div className="w-full">
            <ReactFlow
                nodes={nodesData}
                edges={edgesData}
                onNodesChange={onNodesDataChange}
                onEdgesChange={onEdgesDataChange}
                // style={{ background: initBgColor }}
                nodeTypes={nodeTypes}
                connectionLineStyle={connectionLineStyle}
                snapToGrid={true}
                snapGrid={snapGrid}
                defaultZoom={1.5}
                fitView
                attributionPosition="bottom-left"
            >
                <Controls />
            </ReactFlow>
        </div>
    )
}

export default QueryFlow