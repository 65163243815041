const { createTheme } = require("@mui/material");

const theme = createTheme({
    palette: {
        tcareRuby: {
            main: '#FE4672'
        },
        tcareDarkPurple: {
            main: '#391A52'
        },
        tcareCream: {
            main: '#FEF9F1'
        },
        tcarePurple: {
            main: '#611C73'
        },
        tcareLavendar: {
            main: '#D494FB'
        },
        tcarePeriwinkle: {
            main: '#B6CAFF'
        },
        tcareAqua: {
            main: '#B9E4F2'
        },
        tcareMint: {
            main: '#D6F9EA'
        },
        lightGray: {
            main: 'rgb(156 163 175)'
        },
        white: {
            main: '#ffffff'
        }
    },
});

export default theme