import { Checkbox } from "antd"
import { Handle } from "react-flow-renderer"
import { useDispatch, useSelector } from "react-redux"
import { setFieldsData } from "../store/common/reducers"

const FlowNode = ({ data }) => {

    const { fieldsData } = useSelector(state => state.common)

    const dispatch = useDispatch()

    const valueChanged = (val, name) => {
        let r = {...fieldsData}
        if(r[data.data.name] === undefined) {
            r[data.data.name] = {}
        }
        r[data.data.name] = {...r[data.data.name], [name]: val}
        dispatch(setFieldsData(r))
    }
    
    return (
        <div className="bg-bluegray-200 rounded-lg">
            <Handle
                type="target"
                position="left"
                style={{ background: '#555' }}
            />
            <div className="font-bold text-xl px-4 pt-3 pb-2 bg-tcareAqua rounded-t-lg">
                { data.data.name }
            </div>
            <div className="mt-2 px-4 pb-3">
                {
                    Object.keys(data.data.fields).map(c => {
                        let r = data.data.fields[c]
                        let y = data.nodes.find(e => e.name === r.type)
                        return (
                            <div key={r.name} className="text-lg">
                                <Checkbox disabled={r.name === 'pageNumber' || r.name === 'pageSize' || r.name === 'join' || r.name === 'email'} checked={fieldsData[data.data.name][c]} className="font-semibold" onChange={val => valueChanged(val.target.checked, r.name)}>
                                    <div className={`${y === undefined ? '' : 'bg-tcareMint rounded'} px-1 leading-5 py-0.5`}>{r.name}<br />{y === undefined ? '' : ` (${r.type})`}</div>
                                </Checkbox>
                            </div>
                        )
                    })
                }
            </div>
            <Handle
                type="source"
                position="right"
                id="a"
                style={{ background: '#555' }}
            />
        </div>
    )
}

export default FlowNode