import { Upload } from 'antd';
import { AiOutlineCloudUpload } from "react-icons/ai"

const { Dragger } = Upload;

const DragUpload = ({ onFileUpload }) => {

    const props = {
        name: 'file',
        multiple: false,
        accept: ".csv",

        onChange(info) {
            // const { status } = info.file;

            // if (status !== 'uploading') {
            //     console.log(info.file, info.fileList);
            // }

            // if (status === 'done') {
                console.log(`${info.file.name} file uploaded successfully.`);
                if(typeof onFileUpload === 'function') onFileUpload(info.file)
            // } else if (status === 'error') {
                // alert(`${info.file.name} file upload failed.`);
            // }
        },

        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <div>
            <Dragger {...props}>
                <p className="flex justify-center">
                    <AiOutlineCloudUpload fontSize="60px" />
                </p>
                <p className="ant-upload-text">Click or drag csv file to this area to upload</p>
            </Dragger>
        </div>
    )
}

export default DragUpload