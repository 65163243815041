export const commonState = {
    fieldsData: {},
    displayDataTree: [],
    pageLoading: false,
    searchArgs: [],
    searchValues: null,
    lastQuery: '',
    addFormObjectTree: [],
    addFormValues: null,
    addFormColumns: null,
    orgNames: [],
    showFullForm: false,
    prefillValues: {}
}