import { gql } from "@apollo/client";

export const AzureUserByEmail = gql`
    query {
        azureUserByEmail(email: "soorath@tcare.ai") {
            id
            firstName
        }
    }
`;

export const InitiaMutationQuery = gql`
    mutation AddTodo($type: String!) {
        addTodo(type: $type) {
            id
            type
        }
    }
`;