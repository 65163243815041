import { useDispatch, useSelector } from "react-redux"
import { Button, Checkbox, Input, InputNumber, Select } from 'antd'
import { createInputObject, getFormValue } from '../data/parseSchema'
import { setSearchValues } from '../store/common/reducers';
import { RiDeleteBin5Line } from 'react-icons/ri'

const { Option } = Select;

const BuildSearchBlock = ({ blocks, name }) => {

    const { searchValues } = useSelector(state => state.common)
    
    const dispatch = useDispatch()

    const loopChangeValues = (name, value, currentValue, action="update") => {
        let nameSplit = name.split('.')
        if(nameSplit.length > 1) {
            value = loopChangeValues(nameSplit.slice(1,).join('.'), value, currentValue[nameSplit[0]], action)
        }
        if(typeof currentValue === 'object' && Array.isArray(currentValue)) {
            if(action === 'update' || nameSplit.length > 1) {
                return currentValue.map((c, i) => {
                    if(i === parseInt(nameSplit[0])) {
                        return value
                    }
                    return c
                })
            } else if(action === 'insert') {
                return [...currentValue, value]
            } else if(action === 'remove') {
                return currentValue.filter((c, i) => i !== parseInt(nameSplit[0]))
            }
        } else if(typeof currentValue === 'object') {
            return {...currentValue, [nameSplit[0]]: value}
        }
    }

    const changeValue = (name, value) => {
        let v = loopChangeValues(name, value, searchValues)
        dispatch(setSearchValues(v))
    }

    const appendRow = (name, row) => {
        let c = createInputObject(row)
        let v = loopChangeValues(name+'.0', c, searchValues, 'insert')
        dispatch(setSearchValues(v))
    }

    const removeRow = (name) => {
        let v = loopChangeValues(name, '', searchValues, 'remove')
        dispatch(setSearchValues(v))
    }

    return <div className='flex flex-wrap items-center gap-4'>
        {
            blocks.map((c, i) => {
                if(c.name === 'pageable') return null
                let n = `${name}${name !== '' ? '.' : ''}${c.name}`
                let input = null
                if(c.name === 'active') {
                    input = <Select value={getFormValue(n, searchValues)} allowClear style={{ width: '200px' }} placeholder={`${c.name}`} onChange={val => changeValue(n, val)} bordered={false} className="!bg-bluegray-600 text-white" >
                        <Option value="null">All</Option>
                        <Option value="true">Active</Option>
                        <Option value="false">In Active</Option>
                    </Select>
                } else if (c.choices.length > 0 && c.list) {
                    input = <Select value={getFormValue(n, searchValues)} mode="multiple" allowClear style={{ width: '200px' }} placeholder={`Please select ${c.name}`} onChange={val => changeValue(n, val)} bordered={false} className="!bg-bluegray-600 text-white" >
                        {
                            c.choices.map(e => (
                                <Option key={e.value} value={e.value}>{ e.text }</Option>
                            ))
                        }
                    </Select>
                } else if(c.choices.length > 0) {
                    input = <Select value={getFormValue(n, searchValues)} style={{ width: '250px' }} placeholder={`Please select ${c.name}`} onChange={val => changeValue(n, val)} bordered={false} className="!bg-bluegray-600 text-white" >
                        {
                            c.choices.map(e => (
                                <Option key={e.value} value={e.value}>{ e.text }</Option>
                            ))
                        }
                    </Select>
                } else if(c.list && c.children.length === 0) {
                    input = <Select mode="tags" style={{ width: '250px' }} placeholder={`${c.name}`} onChange={val => changeValue(n, val)} bordered={false} className="!bg-bluegray-600 text-white">
                            </Select>
                } else if(c.type === 'Int') {
                    input = <InputNumber style={{ width: '250px' }} value={getFormValue(n, searchValues)} size='middle' placeholder={c.name} onChange={val => changeValue(n, val)} bordered={false} className="!bg-bluegray-600 text-white" />
                } else if(c.type === 'String') {
                    input = <Input style={{ width: '250px' }} placeholder={c.name} value={getFormValue(n, searchValues)} onChange={val => changeValue(n, val.target.value)} bordered={false} className="!bg-bluegray-600 text-white" />
                } else if(c.type === 'Boolean') {
                    let checkVal = getFormValue(n, searchValues)
                    input = <Checkbox value={checkVal} onChange={val => changeValue(n, !checkVal)} />
                } else if(c.list && c.children.length > 0) {
                    return <div key={i} className="border border-tcareDarkPurple rounded p-2">
                        <div className='flex items-center gap-3'>
                            <div className='font-bold capitalize text-white'>{c.name}</div>
                            <Button size='small' className='!bg-tcareRuby !text-white font-bold px-3 border-0' onClick={() => appendRow(n, c.children)}>Add {c.name}</Button>
                        </div>
                        <div className="pb-4">
                            {
                                getFormValue(n, searchValues).map((r, j) => {
                                    let y = `${n}.${j}`
                                    return <div key={j} className="relative m-2 border-2 border-tcareDarkPurple rounded p-2">
                                        <div className="absolute top-2 right-2 cursor-pointer" onClick={() => removeRow(y)}><RiDeleteBin5Line size="20px" className="text-red-700" /></div>
                                        <BuildSearchBlock key={j} blocks={c.children} name={y} />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                } else if (c.children.length > 0) {
                    return <BuildSearchBlock key={i} blocks={c.children} name={n} />
                }
                return <div key={i} className="flex flex-col">
                    <div className="text-white capitalize font-semibold text-xs">{c.name}</div>
                    <div>{ input }</div>
                </div>
            })
        }
    </div>
}

export default BuildSearchBlock